import React from "react"
import PropTypes from "prop-types"

import MenuPC from "./MenuPC"
import MenuMobile from "./MenuMobile"
import TitrePage from "../TitrePage"
import Layout from "../Layout"
import Lien from "../../../composants/navigation/Lien"

export default function LayoutEN(props) {
  return (
    <Layout page={props.page}>
      <div id="menu-pc-conteneur">
        <div id="menu-pc-espace-avant-menu"></div>
        <MenuPC page="contenu" />
      </div>
      <main id="main-conteneur">
        <MenuMobile />
        <article id="article-conteneur">
          <TitrePage page={props.page} />
          {props.children}{" "}
          {/*la propriété contenant le contenu s'appellera forcément children*/}
        </article>
        <footer id="pied-page-conteneur">
          <div>
            © Bastien Foucher - <Lien codePageCible="mentionsLegalesEN" /> -{" "}
            <Lien codePageCible="conditionsGeneralesDeVenteEN" />
          </div>
          <div>
            A low-consumption website. -{" "}
            <strong>
              <Lien
                codePageCible="aProposEN"
                ancre="section-numerique-responsable"
              >
                What is it
              </Lien>
            </strong>
            ?
          </div>
        </footer>
      </main>
    </Layout>
  )
}

LayoutEN.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  page: PropTypes.object,
}

import React from "react"
import { Link } from "gatsby"

import Lien from "../../navigation/Lien"
import { locales } from "../../../../locales"

const LANGUE = "en"

export default function MenuMobile() {
  return (
    <nav id="menu-mobile">
      <div id="menu-mobile-barre">
        <a
          id="menu-mobile-icone"
          className="menu-mobile-icone-ouvrir"
          onClick={deroulerMenuMobile}
          style={{ cursor: "pointer" }}
          aria-label={locales[LANGUE].site.ouvrirFermerMenu}
          href="#"
        ></a>
        <Link aria-label={locales[LANGUE].site.accueil} to="/en">
          <div className="menu-mobile-logo menu-mobile-logo-fonce"></div>
        </Link>
        <div id="menu-mobile-espace-apres-titre"></div>
      </div>
      <ul id="menu-mobile-items">
        <li className="menu-mobile-item" onClick={deroulerMenuMobile}>
          <Lien codePageCible="indexGaleriesEN" />
        </li>
        <li className="menu-mobile-item" onClick={deroulerMenuMobile}>
          <Lien codePageCible="indexTutorielsEN" onClick={deroulerMenuMobile} />
        </li>
        <li className="menu-mobile-item" onClick={deroulerMenuMobile}>
          <Lien codePageCible="boutiqueEN" onClick={deroulerMenuMobile} />
        </li>
        <li className="menu-mobile-item" onClick={deroulerMenuMobile}>
          <Lien codePageCible="aProposEN" />
        </li>
        <li className="menu-mobile-item" onClick={deroulerMenuMobile}>
          <Lien codePageCible="contactEN" onClick={deroulerMenuMobile} />
        </li>
      </ul>
    </nav>
  )
}

function deroulerMenuMobile() {
  let menu = document.getElementById("menu-mobile-items")
  let icone = document.getElementById("menu-mobile-icone")

  if (menu.style.display === "none" || menu.style.display === "") {
    menu.style.display = "flex"
    /*je suis obligé de passer par une classe pour changer l'image ; backgroundImage ne fonctionne pas bien avec
    Gatsby à cause du chemin d'accès qui lui fonctionne bien dans les classes CSS*/
    icone.className = "menu-mobile-icone-fermer"
  } else {
    menu.style.display = "none"
    icone.className = "menu-mobile-icone-ouvrir"
  }
}

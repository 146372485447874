import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import BoutonFacebook from "../../boutons/BoutonFacebook"
import Lien from "../../navigation/Lien"
import { locales } from "../../../../locales"

const LANGUE = "en"

export default function MenuPC(props) {
  let classeCSSCouleurFond, classeLogo, classeCSSReseauxSociaux

  if (props.page === "index") {
    classeCSSCouleurFond = "menu-pc-couleur-fond-clair"
    classeCSSReseauxSociaux = "menu-pc-reseaux-sociaux-fonce"
    classeLogo = "menu-pc-logo menu-pc-logo-fonce"
  } else {
    classeCSSCouleurFond = "menu-pc-couleur-fond-fonce"
    classeCSSReseauxSociaux = "menu-pc-reseaux-sociaux-clair"
    classeLogo = "menu-pc-logo menu-pc-logo-clair"
  }

  return (
    <nav id="menu-pc">
      <div id="menu-pc-avec-background-color" className={classeCSSCouleurFond}>
        <Link aria-label={locales[LANGUE].site.accueil} to="/en">
          <div className={classeLogo}></div>
        </Link>
        <ul id="menu-pc-items">
          <li className="menu-pc-item">
            <Lien codePageCible="indexGaleriesEN" />
          </li>
          <li className="menu-pc-item">
            <Lien codePageCible="indexTutorielsEN" />
          </li>
          <li className="menu-pc-item">
            <Lien codePageCible="boutiqueEN" />
          </li>
          <li className="menu-pc-item">
            <Lien codePageCible="aProposEN" />
          </li>
          <li className="menu-pc-item">
            <Lien codePageCible="contactEN" />
          </li>
        </ul>
        <BoutonFacebook
          lien="https://www.facebook.com/BastienFoucherAstrophotographies"
          classeCSS={classeCSSReseauxSociaux}
        />
      </div>
    </nav>
  )
}

MenuPC.propTypes = {
  pages: PropTypes.string,
  page: PropTypes.string,
}
